*{
  box-sizing: border-box;
  align-items: center;
}
  
main{
  padding: 30px;
  padding-top: 0px;
  max-width: 100%;
  margin: 0 auto;

}

header{
  display: flex;
  justify-content:space-between;
  margin-top: 10px;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

header a{
  text-decoration:none;
  color: #5d5656c6;
}

header a.logo{
  font-weight: bold;
  font-size: 2rem;
}
header nav{
  display: flex;
  gap: 30px;
  justify-content:flex-end;
  font-weight: bold;
  font-size: 1.9rem;
  text-align: center;

}

div.post{
  display: grid;
  grid-template-columns: .9fr 1.1fr;
  gap: 20px;
  margin-bottom:  100px;
  margin-top:     100px;
  padding-left:   100px;
 

}
div.post div.texts h2{
  margin: 0;
  font-size: 1.9rem;
  color: #a08563;
}

div.post p.info{
  margin: 6px,0;
  color: #888;
  font-size: .7rem;
  font-weight: bold;
  display: flex;
  gap: 10px;

}

div.post p.info a.author{
color: #6b6b6b;
border-bottom: 1px solid #222;
}

div.post p.summary{
  margin: 10px,0;
  line-height: 1.4rem;
}

form.login, form.register {
  padding-top: 60px;
  max-width: 500px;
  margin: 0 auto;
}

input{
  display: block;
  margin-bottom: 5px;
  width: 100%;
  padding: 5px 7px;
  border: 2px solid #ddd;
  border-radius: 5px;
  background-color: #fff;
  
}

button {
  width: 100%;
  display: block;
  background-color: #555;
  border: 0;
  color: #fff;
  border-radius: 5px;
  padding: 7px 0;
}

form h1{
  text-align:center;
  color: #a08563;

}

div.interface{
  max-width: 90%; 
  padding-top: 30px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 100px;
  margin-left: 50px;
  
}

div.interface div.title{
  display: grid;
  grid-template-columns: 1fr 0.2fr;
  gap: 15px;
}
div.interface div.title h1{
  color:  #a08563;;
  font-weight: bold;
  font-size: 1.8rem;
  text-align: center;
  margin-left: 180px;
}
div.interface div.title div.image{
  max-width:100%;
  align-content: center;
}

div.window {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  margin-bottom: 90px;
  margin-top: 50px;
  padding: 15px;
  border-radius: 20px;
  border:#222 2px solid;
  background-color: #0000006f;
}

div.window div.options{
 
  text-align:center;
  color:#613030;
  background-color: #20202066; 
  border-radius: 20px;
}
