/*Desktop*/
main{
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  padding-bottom:0px;
 
}
body{
  background-image: url('./background.jpg');
  background-repeat:no-repeat;
  background-size: cover;
  background-color: #fff;
}
header{
  display: flex;
  justify-content:space-between;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-bottom: 10px;
  padding-top: 10px;
  background-color: #000;
}

header a{
  text-decoration:none;
  color: #fff;
  /*color: #5d5656c6;*/
}

header a.logo{
  font-weight: bold;
  font-size: 2rem;
}
header nav{
  display: flex;
  gap: 30px;
  justify-content:flex-end;
  font-weight: bold;
  font-size: 1.9rem;
  text-align: right;
  padding-right:0px;

}

body {
  color: #000;
  background-color: #fff;
} 
div.welcome{
    margin-top: 0px;
    margin-left: 0px; 
    margin-right: 0px;
    padding-top: 0px; 
    }
    div.welcome div.logo{
    margin-left: 470px;
    margin-right: 150px;
    margin-bottom: 0px;
    margin-top: 90px;
    padding-top: 0px;
    padding-bottom: 0;
    }
    div.logo img{
      width:250px;
      justify-content: center;
    }
    div.welcome div.text h1{
      font-size: 2rem;
      color: #5d5656c6;
      padding-top:0px;
      padding-left:40px;
      padding-right: 0px;
      margin-top: 0px;
      margin-bottom:0px;
      margin-left: 400px;
      margin-right: 400px;
      border-top: 2px #271a06 solid;
      border-width: 4px;
    }
    div.welcome div.text p.text{
      font-size: 1.5rem;
      padding-left: 510px;
      margin-top: 0;
      color: #6e6e6e;
      /*background-color: #271a06;*/
    }
    
div.test-1 p{
  position: absolute;
  right:0px;
  bottom:-15px;
  font-size: 1rem;
  color: #000;
}
div.test-1 img{
  max-width: 100%;
  width:200px;
  position: absolute;
  bottom:180px;
  left:540px;
  background-color: #fff;
}

div.test-1 phone{
  display: none;
  font-size: 2rem;
  position: absolute;
  top:420px;
  left:105px;
}
div.test-1 landscape{
  display: none;
  font-size: 2rem;
  position: absolute;
  top:420px;
  left:105px;
}
div.test-1 desktop{
  display: block;
  font-size: 2rem;
  position: absolute;
  top:379px;
  left:587px;
  background-color: #fff;
}

/*Portrait mode*/
@media screen and (max-width: 384px) {
  main{
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
    padding-bottom:5px;
  }
  body{
    background-color:#fff;
    background-image: url('./background.jpg');
  background-repeat:inherit;
  background-size:cover;
  background-color: #fff;
  }
  header{
    display: flex;
    justify-content:space-between;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-bottom: 10px;
    padding-top: 10px;
  }
  
  header a{
    text-decoration:none;
    color: #fff;
    /*color: #5d5656c6;*/
  }
  
  header a.logo{
    font-weight: bold;
    font-size: 1.8rem;
  }
  header nav{
    display: flex;
    gap: 15px;
    justify-content:flex-end;
    font-weight: bold;
    font-size: 1.2rem;
    text-align: right;
    padding-right:0px;
  
  }
  div.test-1 phone{
    display: block;
    font-size: 2rem;
    position: absolute;
    top:420px;
    left:100px;
    background-color: #fff;
  }
  div.test-1 landscape{
    display: none;
    font-size: 2rem;
    position: absolute;
    top:420px;
    left:105px;
  }
  div.test-1 desktop{
    display: none;
    font-size: 2rem;
    position: absolute;
    top:420px;
    left:135px;
  }
  div.test-1 p{
    position: absolute;
    right:15px;
    bottom:-10px;
    font-size: 1rem;
    color: #000;
    margin-bottom:15px;
  }
  div.test-1 div.image{
    
    border: #bab7b707 3px solid;
    width:150px;
    height:150px;
    position: fixed;
    top:260px;
    left:117px;
    background-color: #fff;
   
  }
  div.test-1 img{
    max-width: 100%;
    width:150px;
    height:150px;
    position: fixed;
    top:260px;
    left:117px;
  }
}

/*Landscape Mode*/
@media screen and (max-width: 806px) and (min-width:385px){
  main{
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
    padding-bottom:5px;
  }
  body{
    background-color:#fff;
  }
  header{
    display: flex;
    justify-content:space-between;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-bottom: 10px;
    padding-top: 10px;
  }
  
  header a{
    text-decoration:none;
    color: #fff;
    /*color: #5d5656c6;*/
  }
  
  header a.logo{
    font-weight: bold;
    font-size: 1.5rem;
  }
  header nav{
    display: flex;
    gap: 20px;
    justify-content:flex-end;
    font-weight: bold;
    font-size: 1.3rem;
    text-align: center;
    padding-right:0px ;
  
  }
  
  div.test-1 phone{
    display: none;
    font-size: 2rem;
    position: absolute;
    top:420px;
    left:105px;
  }
  div.test-1 landscape{
    display: block;
    font-size: 1.9rem;
    position: absolute;
    top:210px;
    left:300px;
    background-color: #fff;
  }
  div.test-1 desktop{
    display: none;
    font-size: 2rem;
    position: absolute;
    top:420px;
    left:135px;
  }
  div.test-1 p{
    position: absolute;
    right:15px;
    bottom:0px;
    font-size: 1rem;
    color: #000;
    margin-bottom: auto;
  }
  
  div.test-1 img{
    max-width: 100%;
    width:100px;
    height:100px;
    position: absolute;
    top:100px;
    left:352px;
    background-color: #fff;
  }
}